import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '../../../assets/image/notepad.png';
import _imports_1 from '../../../assets/image/del.png';
const _hoisted_1 = {
  ref: "PosterHtml"
};
const _hoisted_2 = {
  class: "icon-tk"
};
const _hoisted_3 = {
  class: "tk-box-selec-code"
};
const _hoisted_4 = {
  class: "code"
};
const _hoisted_5 = {
  class: "tk-num"
};
const _hoisted_6 = {
  key: 0,
  class: "tk-box-selec-code shama"
};
const _hoisted_7 = {
  class: "code"
};
const _hoisted_8 = {
  class: "tk-num"
};
const _hoisted_9 = {
  class: "tk-code-handle"
};
const _hoisted_10 = ["onClick"];
const _hoisted_11 = {
  key: 0,
  href: "javascript:;"
};
const _hoisted_12 = {
  key: 1
};
const _hoisted_13 = {
  class: "tk-assistant-box"
};
const _hoisted_14 = {
  class: "tk-code-type"
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "sama"
};
const _hoisted_17 = {
  class: "sama-title"
};
const _hoisted_18 = {
  class: "sama-box"
};
const _hoisted_19 = ["onClick"];
import { computed, ref } from "vue";
import { reactive, toRefs } from "vue";
import Dialog from '@/components/mode/message';
import { useRouter } from "vue-router";
// import html2canvas from "html2canvas"

// let PosterHtml = ref(null)
//     /**
//      * 海报生成
//      * */
//      const createPoster = () => {

//       // 生成海报
//       const domObj = PosterHtml.value
//       // 清晰度关键  像素比
//       const dpi = DPR()
//       html2canvas(domObj, {
//         useCORS: true, // 是否允许图片跨域
//         allowTaint: true,
//         scrollY: 0,
//         scrollX: 0,
//         y:0,
//         logging: false, // 日志
//         width: domObj.offsetWidth, // 图片宽度
//         height: domObj.offsetHeight,
//         scale: dpi,
//         backgroundColor:'#ffffff',
//       }).then(async canvas => {
//         // showToast('生成海报成功~')
//         // 在微信里,可长按保存或转发
//         console.log(canvas.toDataURL('image/jpeg'))
//         data.posterImg = canvas.toDataURL('image/jpeg')
//         // // flag：生成后展示图片
//         // data.flag = true
//         // emit('showHp')
//         // if (
//         //   isApp.value ||
//         //   navigator.userAgent.indexOf('UCBrowser') > -1 ||
//         //   navigator.userAgent.toLowerCase().toString().indexOf('qqbrowser') > -1
//         // ) {
//         //   const hbData = data.posterImg.split(',')
//         //   try {
//         //     const params = {
//         //       data: hbData[1],
//         //       // keepSrc: 'yes',
//         //       // readExif: 'no',
//         //     }
//         //     const res = (await startComentRepos.uploadQuickStream(
//         //       params,
//         //     )) as any
//         //     const resJson = JSON.parse(res)
//         //     console.log('文件', resJson)
//         //     data.posterUrl = resJson.files[0].url
//         //     console.log('posterUrl', data.posterUrl)
//         //   } catch (error) {
//         //     console.error(error)
//         //   }
//         //   // uc兼容
//         //   if (
//         //     navigator.userAgent.indexOf('UCBrowser') > -1 ||
//         //     navigator.userAgent.toLowerCase().toString().indexOf('qqbrowser') >
//         //       -1
//         //   ) {
//         //     data.posterImg = data.posterUrl
//         //   }
//         // }
//         // console.log('海报生成成功===>', data.posterImg)
//       })
//     }

//     // 获取像素比
//     const DPR = () => {
//       // 获取设备dpi
//       if (window.devicePixelRatio && window.devicePixelRatio > 1) {
//         return window.devicePixelRatio * 2
//       }
//       // 直接返回高像素比
//       return 8
//     }

export default {
  __name: 'assistant',
  setup(__props) {
    let router = useRouter();
    let data = reactive({
      posterImg: '',
      handleObjct: [{
        name: '杀码'
      }, {
        name: '存到记事本'
      }, {
        name: '复制'
      }, {
        name: '分享'
      }],
      boxList: [{
        title: '生肖',
        content: [{
          name: '龙',
          num: [2, 14, 26, 38]
        }, {
          name: '兔',
          num: [3, 15, 27, 39]
        }, {
          name: '虎',
          num: [4, 16, 28, 40]
        }, {
          name: '牛',
          num: [5, 17, 29, 41]
        }, {
          name: '鼠',
          num: [6, 18, 30, 42]
        }, {
          name: '猪',
          num: [7, 19, 31, 43]
        }, {
          name: '狗',
          num: [8, 20, 32, 44]
        }, {
          name: '鸡',
          num: [9, 21, 33, 45]
        }, {
          name: '猴',
          num: [10, 22, 34, 46]
        }, {
          name: '羊',
          num: [11, 23, 35, 47]
        }, {
          name: '马',
          num: [12, 24, 36, 48]
        }, {
          name: '蛇',
          num: [1, 13, 25, 37, 49]
        }]
      }, {
        title: '波色',
        content: [{
          name: '红',
          num: [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46]
        }, {
          name: '绿',
          num: [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]
        }, {
          name: '蓝',
          num: [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48]
        }]
      }, {
        title: '五行',
        content: [{
          name: '金',
          num: [3, 4, 11, 12, 25, 26, 33, 34, 41, 42]
        }, {
          name: '木',
          num: [7, 8, 15, 16, 23, 24, 37, 38, 45, 46]
        }, {
          name: '水',
          num: [13, 14, 21, 22, 29, 30, 43, 44]
        }, {
          name: '火',
          num: [1, 2, 9, 10, 17, 18, 31, 32, 39, 40, 47, 48]
        }, {
          name: '土',
          num: [5, 6, 19, 20, 27, 28, 35, 36, 49]
        }]
      }, {
        title: '大小',
        content: [{
          name: '大',
          num: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }, {
          name: '小',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
        }]
      }, {
        title: '单双',
        content: [{
          name: '单',
          num: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49]
        }, {
          name: '双',
          num: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48]
        }]
      }, {
        title: '大小单双',
        content: [{
          name: '大单',
          num: [25, 27, 29, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49]
        }, {
          name: '小单',
          num: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23]
        }, {
          name: '大双',
          num: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48]
        }, {
          name: '小双',
          num: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24]
        }]
      }, {
        title: '波色单双',
        content: [{
          name: '红单',
          num: [1, 7, 13, 19, 23, 29, 35, 45]
        }, {
          name: '红双',
          num: [2, 8, 12, 18, 24, 30, 34, 40, 46]
        }, {
          name: '绿单',
          num: [5, 11, 17, 21, 27, 33, 39, 43, 49]
        }, {
          name: '绿双',
          num: [6, 16, 22, 28, 32, 38, 44]
        }, {
          name: '蓝单',
          num: [3, 9, 15, 25, 31, 37, 41, 47]
        }, {
          name: '蓝双',
          num: [4, 10, 14, 20, 26, 36, 42, 48]
        }]
      }, {
        title: '七段',
        content: [{
          name: '1段',
          num: [1, 2, 3, 4, 5, 6, 7]
        }, {
          name: '2段',
          num: [8, 9, 10, 11, 12, 13, 14]
        }, {
          name: '3段',
          num: [15, 16, 17, 18, 19, 20, 21]
        }, {
          name: '4段',
          num: [22, 23, 24, 25, 26, 27, 28]
        }, {
          name: '5段',
          num: [29, 30, 31, 32, 33, 34, 35]
        }, {
          name: '6段',
          num: [36, 37, 38, 39, 40, 41, 42]
        }, {
          name: '7段',
          num: [43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '五门',
        content: [{
          name: '1门',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }, {
          name: '2门',
          num: [10, 11, 12, 13, 14, 15, 16, 17, 18]
        }, {
          name: '3门',
          num: [19, 20, 21, 22, 23, 24, 25, 26, 27]
        }, {
          name: '4门',
          num: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37]
        }, {
          name: '5门',
          num: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '头数',
        content: [{
          name: '0头',
          num: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }, {
          name: '1头',
          num: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        }, {
          name: '2头',
          num: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
        }, {
          name: '3头',
          num: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39]
        }, {
          name: '4头',
          num: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
        }]
      }, {
        title: '尾数',
        content: [{
          name: '0尾',
          num: [10, 20, 30, 40]
        }, {
          name: '1尾',
          num: [1, 11, 21, 31, 41]
        }, {
          name: '2尾',
          num: [2, 12, 22, 32, 42]
        }, {
          name: '3尾',
          num: [3, 13, 23, 33, 43]
        }, {
          name: '4尾',
          num: [4, 14, 24, 34, 44]
        }, {
          name: '5尾',
          num: [5, 15, 25, 35, 45]
        }, {
          name: '6尾',
          num: [6, 16, 26, 36, 46]
        }, {
          name: '7尾',
          num: [7, 17, 27, 37, 47]
        }, {
          name: '8尾',
          num: [8, 18, 28, 38, 48]
        }, {
          name: '9尾',
          num: [9, 19, 29, 39, 49]
        }]
      }, {
        title: '合数',
        content: [{
          name: '合01',
          num: [1, 10]
        }, {
          name: '合02',
          num: [2, 11, 20]
        }, {
          name: '合03',
          num: [3, 12, 21, 30]
        }, {
          name: '合04',
          num: [4, 13, 22, 31, 40]
        }, {
          name: '合05',
          num: [5, 14, 23, 32, 41]
        }, {
          name: '合06',
          num: [6, 15, 24, 33, 42]
        }, {
          name: '合07',
          num: [7, 16, 25, 34, 43]
        }, {
          name: '合08',
          num: [8, 17, 26, 35, 44]
        }, {
          name: '合09',
          num: [9, 18, 27, 36, 45]
        }, {
          name: '合10',
          num: [19, 28, 37, 46]
        }, {
          name: '合11',
          num: [29, 38, 47]
        }, {
          name: '合12',
          num: [39, 48]
        }, {
          name: '合13',
          num: [49]
        }]
      }, {
        title: '合单双',
        content: [{
          name: '合单',
          num: [1, 3, 5, 7, 9, 10, 12, 14, 16, 18, 21, 23, 25, 27, 29, 30, 32, 34, 36, 38, 41, 43, 45, 47, 49]
        }, {
          name: '合双',
          num: [2, 4, 6, 8, 11, 13, 15, 17, 19, 20, 22, 24, 26, 28, 31, 33, 35, 37, 39, 40, 42, 44, 46, 48]
        }]
      }, {
        title: '余七',
        content: [{
          name: '6余0',
          num: [6, 12, 18, 24, 30, 36, 42, 48]
        }, {
          name: '6余1',
          num: [1, 7, 13, 19, 25, 31, 37, 43, 49]
        }, {
          name: '6余2',
          num: [2, 8, 14, 20, 26, 32, 38, 44]
        }, {
          name: '6余3',
          num: [3, 9, 15, 21, 27, 33, 39, 45]
        }, {
          name: '6余4',
          num: [4, 10, 16, 22, 28, 34, 40, 46]
        }, {
          name: '6余5',
          num: [5, 11, 17, 23, 29, 35, 41, 47]
        }]
      }, {
        title: '余六',
        content: [{
          name: '7余0',
          num: [7, 14, 21, 28, 35, 42, 49]
        }, {
          name: '7余1',
          num: [1, 8, 15, 22, 29, 36, 43]
        }, {
          name: '7余2',
          num: [2, 9, 16, 23, 30, 37, 44]
        }, {
          name: '7余3',
          num: [3, 10, 17, 24, 31, 38, 45]
        }, {
          name: '7余4',
          num: [4, 11, 18, 25, 32, 39, 46]
        }, {
          name: '7余5',
          num: [5, 12, 19, 26, 33, 40, 47]
        }, {
          name: '7余6',
          num: [6, 13, 20, 27, 34, 41, 48]
        }]
      }, {
        title: '其他',
        content: [{
          name: '家禽',
          num: [4, 6, 7, 8, 10, 11, 16, 18, 19, 20, 22, 23, 28, 30, 31, 32, 34, 35, 40, 42, 43, 44, 46, 47]
        }, {
          name: '野兽',
          num: [1, 2, 3, 5, 9, 12, 13, 14, 15, 17, 21, 24, 25, 26, 27, 29, 33, 36, 37, 38, 39, 41, 45, 48, 49]
        }, {
          name: '单笔',
          num: [1, 5, 6, 8, 11, 12, 13, 17, 18, 20, 23, 24, 25, 29, 30, 32, 35, 36, 37, 41, 42, 44, 47, 48]
        }, {
          name: '双笔',
          num: [2, 3, 4, 7, 9, 10, 14, 15, 16, 19, 21, 22, 26, 27, 28, 31, 33, 34, 38, 39, 40, 43, 45, 46, 49]
        }, {
          name: '女肖',
          num: [2, 6, 7, 8, 10, 12, 14, 18, 20, 22, 24, 26, 30, 32, 34, 36, 38, 42, 44, 46, 48]
        }, {
          name: '男肖',
          num: [1, 3, 4, 5, 9, 11, 13, 15, 16, 17, 18, 19, 21, 23, 25, 27, 28, 29, 31, 33, 35, 37, 39, 40, 41, 43, 47, 49]
        }, {
          name: '吉美',
          num: [1, 2, 8, 10, 11, 12, 13, 14, 20, 22, 23, 24, 25, 26, 32, 34, 35, 36, 37, 38, 44, 46, 47, 48]
        }, {
          name: '凶丑',
          num: [3, 4, 5, 6, 7, 9, 15, 16, 17, 18, 19, 21, 27, 28, 29, 30, 31, 33, 39, 40, 41, 42, 43, 45, 49]
        }, {
          name: '天肖',
          num: [1, 2, 4, 6, 9, 11, 13, 14, 16, 18, 21, 23, 25, 26, 28, 30, 33, 35, 37, 38, 40, 42, 45, 47]
        }, {
          name: '地肖',
          num: [3, 5, 7, 8, 10, 12, 15, 17, 19, 20, 22, 24, 27, 29, 31, 32, 34, 36, 39, 41, 43, 44, 46, 48, 49]
        }, {
          name: '阴性',
          num: [1, 5, 6, 7, 11, 12, 13, 17, 18, 19, 23, 24, 25, 29, 30, 31, 35, 36, 37, 41, 42, 43, 47, 48]
        }, {
          name: '阳性',
          num: [2, 3, 4, 8, 9, 10, 14, 15, 16, 20, 21, 22, 26, 27, 28, 32, 33, 34, 38, 39, 40, 44, 45, 46, 49]
        }, {
          name: '白边',
          num: [3, 4, 5, 6, 7, 8, 15, 16, 17, 18, 19, 20, 27, 28, 29, 30, 31, 32, 39, 40, 41, 42, 43, 44]
        }, {
          name: '黑中',
          num: [1, 2, 9, 10, 11, 12, 13, 14, 21, 22, 23, 24, 25, 26, 33, 34, 35, 36, 37, 38, 45, 46, 47, 48, 49]
        }, {
          name: '红肖',
          num: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47]
        }, {
          name: '蓝肖',
          num: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48]
        }, {
          name: '绿肖',
          num: [1, 4, 7, 10, 12, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49]
        }, {
          name: '琴',
          num: [2, 8, 12, 14, 20, 24, 26, 32, 36, 38, 44, 48]
        }, {
          name: '棋',
          num: [4, 5, 7, 16, 17, 19, 28, 29, 31, 40, 41, 43]
        }, {
          name: '书',
          num: [1, 3, 11, 13, 15, 23, 25, 27, 35, 37, 39, 47, 49]
        }, {
          name: '画',
          num: [6, 9, 10, 18, 21, 22, 30, 33, 34, 42, 45, 46]
        }, {
          name: '五福肖',
          num: [2, 3, 5, 9, 12, 14, 15, 17, 21, 24, 26, 27, 29, 33, 36, 38, 39, 41, 45, 48]
        }]
      }],
      selectNum: [],
      //选中的号码对象数组
      yixuanList: [] //交集
    });
    const oldyixuanList = ref([]);
    let {
      handleObjct,
      boxList,
      yixuanList
    } = toRefs(data);
    let handleSelect = (item, index, k) => {
      if (item.active) {
        item.active = false;
        var name = item.name;
        var newArr = data.selectNum.reduce((total, current) => {
          current.name !== name && total.push(current);
          return total;
        }, []);
        data.selectNum = newArr;
      } else {
        item.active = true;
        item.i = k;
        // Vue.set(item,'active',true);
        // Vue.set(item,'i',k);
        let arr = data.selectNum.concat(item);
        data.selectNum = arr;
      }
      if (data.selectNum.length > 0) {
        var iarr = [];
        for (var j = 0; j < data.selectNum.length; j++) {
          var n = data.selectNum[j].i;
          if (iarr[n]) {
            iarr[n] = iarr[n].concat(data.selectNum[j].num);
          } else {
            iarr[n] = data.selectNum[j].num;
          }
        }
        var jarr = [];
        for (var a = 0; a < iarr.length; a++) {
          if (iarr[a]) {
            if (jarr.length == 0) {
              jarr = iarr[a];
            } else {
              jarr = jarr.filter(item => iarr[a].includes(item));
            }
          } else {
            continue;
          }
        }
        data.yixuanList = jarr.sort(function (a, b) {
          return a - b;
        });
        oldyixuanList.value = JSON.parse(JSON.stringify(data.yixuanList));
        console.log(shaMaSelects.value);
        for (let j = 0; j < shaMaSelects.value.length; j++) {
          for (let i = 0; i < data.yixuanList.length; i++) {
            if (data.yixuanList[i] == shaMaSelects.value[j]) {
              console.log(i);
              data.yixuanList.splice(i, 1);
            }
          }
        }
      } else {
        data.yixuanList = [];
        oldyixuanList.value = JSON.parse(JSON.stringify(data.yixuanList));
      }
    };
    let fullName = computed(() => item => {
      //计算属性传递参数
      if (item < 10) {
        return '0' + item;
      } else {
        return item;
      }
    });
    let styles = computed(() => item => {
      //计算属性传递参数
      return colorarray[cons(item, numarrays)];
    });
    let handleClick = async index => {
      if (index == 0) {
        show.value = !show.value;
      }
      if (data.yixuanList.length == 0) {
        document.getElementsByTagName('a')[0].href = "javascript:;";
        return false;
      }
      if (index == 1) {
        if (localStorage.getItem('notepad') == null) {
          var datas = [];
        } else {
          var datas = JSON.parse(localStorage.getItem('notepad'));
        }
        datas.push({
          codeString: stringCode(data.yixuanList),
          timestamp: Date.parse(new Date()),
          //1610075969000 
          num: data.yixuanList.length
        });
        localStorage.setItem('notepad', JSON.stringify(datas));
        Dialog('保存成功');
      }
      if (index == 2) {
        copyText(stringCode(data.yixuanList));
        // try {
        //     await navigator.clipboard.writeText(stringCode(data.yixuanList));
        //     Dialog('复制成功，赶快去给好友分享吧！')
        //     } catch (err) {
        //         Dialog('复制失败！！')
        //     console.error('Failed to copy: ', err);
        // }
      }
      if (index == 3) {
        createPoster();
        // doAction('10086',stringCode(data.yixuanList))
      }
    };
    const copyText = async val => {
      if (navigator.clipboard && navigator.permissions) {
        await navigator.clipboard.writeText(val);
        Dialog("复制成功！");
      } else {
        const textArea = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        Dialog("复制成功！");
      }
    };
    let doAction = (tel, des) => {
      var u = navigator.userAgent;
      //判断是否为android终端
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      //判断是否为ios终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      //获取要分享的端口号
      // var tel= document.getElementById('tel').value;
      //这里填写要分享的内容

      //ios终端
      if (isiOS) {
        document.getElementsByTagName('a')[0].href = "sms:" + tel + "&body=" + des;
        //安卓终端
      } else {
        document.getElementsByTagName('a')[0].href = "sms:" + tel + "?body=" + des;
      }
    };
    let handleClear = () => {
      for (var i = 0; i < data.boxList.length; i++) {
        for (var j = 0; j < data.boxList[i]['content'].length; j++) {
          data.boxList[i]['content'][j].active = false;
        }
      }
      data.yixuanList.length = 0;
      data.selectNum.length = 0;
    };
    let stringCode = data => {
      let d = data;
      let r = [];
      for (var i = 0; i < d.length; i++) {
        if (d[i] < 10) {
          r[i] = '0' + d[i];
        } else {
          r[i] = d[i];
        }
      }
      r = r.join(',');
      return r;
    };
    let copyContent = async url => {
      try {
        await navigator.clipboard.writeText(url);
        Dialog('复制成功，赶快去给好友分享吧！');
      } catch (err) {
        Dialog('复制失败！！');
        console.error('Failed to copy: ', err);
      }
    };
    let numarrays = [[1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46], [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48], [5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49]];
    let cons = (d, array) => {
      let b = 0;
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          if (array[i][j] == d) {
            b = i;
          }
        }
      }
      return b;
    };
    let colorarray = ['red', 'blue', 'green'];
    let handleRouter = () => {
      router.push({
        path: '/tool/assistant/notepad'
      });
    };
    // 杀码的列表和循环
    const samaList = reactive([]);
    const handleSaMa = () => {
      for (var i = 1; i <= 49; i++) {
        samaList.push({
          name: i,
          num: [i]
        });
      }
      data.boxList[0].content.forEach(item => {
        samaList.push({
          name: item.name,
          num: item.num
        });
      });
    };
    handleSaMa();
    const shamaList = ref(JSON.parse(JSON.stringify(samaList)));
    const show = ref(false);
    //点击杀码里面的内容
    const shaMaSelect = reactive([]);
    const shaMaSelects = ref([]);
    const shamashow = ref(false);
    const shamaname = ref([]);
    const handleSelectSaMa = (item, start, index) => {
      shamashow.value = true;
      // 并集 数组去重 
      if (start == 'green') {
        shaMaSelect.push(...item.num);
        shaMaSelects.value = unique(shaMaSelect);
        shaMaSelect.length = 0;
        shaMaSelect.push(...shaMaSelects.value);
        shamaname.value.push(item.name);
        for (let i = 0; i < data.yixuanList.length; i++) {
          for (let j = 0; j < shaMaSelects.value.length; j++) {
            if (data.yixuanList[i] == shaMaSelects.value[j]) {
              data.yixuanList.splice(i, 1);
            }
          }
        }
      } else {
        if (shamaname.value.indexOf(item.name) != -1) {
          shamaname.value.splice(shamaname.value.indexOf(item.name), 1);
          for (let x = 0; x < oldyixuanList.value.length; x++) {
            for (let y = 0; y < shamaList.value[index].num.length; y++) {
              if (oldyixuanList.value[x] == shamaList.value[index].num[y]) {
                data.yixuanList.push(shamaList.value[index].num[y]);
              }
            }
          }
          for (let i = 0; i < shaMaSelects.value.length; i++) {
            for (let j = 0; j < item.num.length; j++) {
              if (shaMaSelects.value[i] == item.num[j]) {
                shaMaSelects.value.splice(i, 1);
              }
            }
          }
        }
        shaMaSelect.length = 0;
        shaMaSelect.push(...shaMaSelects.value);
        data.yixuanList = unique(data.yixuanList);
      }
    };
    let shamabg = computed(() => item => {
      //计算属性传递参数
      if (shamaname.value.indexOf(item) == -1) {
        return 'green';
      } else {
        return 'blueOne';
      }
    });
    let handleClearSm = () => {
      shaMaSelects.value.length = 0;
      shamashow.value = false;
      data.yixuanList = oldyixuanList.value;
    };
    function unique(arr) {
      if (!Array.isArray(arr)) {
        console.log('type error!');
        return;
      }
      var array = [];
      for (var i = 0; i < arr.length; i++) {
        if (!array.includes(arr[i])) {
          //includes 检测数组是否有某个值
          array.push(arr[i]);
        }
      }
      return array;
    }
    return (_ctx, _cache) => {
      const _component_headNav = _resolveComponent("headNav");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_headNav, {
        title: "挑码助手",
        resize: true
      }, {
        iocn: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: _imports_0,
          alt: "",
          onClick: _cache[0] || (_cache[0] = (...args) => _unref(handleRouter) && _unref(handleRouter)(...args))
        })])]),
        tkCont: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(yixuanList), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
            class: _normalizeClass('tk-code ' + _unref(styles)(item))
          }, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fullName)(item)), 1)], 2)])]);
        }), 128))]), _createElementVNode("div", {
          class: "del",
          onClick: _cache[1] || (_cache[1] = (...args) => _unref(handleClear) && _unref(handleClear)(...args))
        }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)]))]), shaMaSelects.value.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shaMaSelects.value, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
            class: _normalizeClass('tk-code ' + _unref(styles)(item))
          }, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(fullName)(item)), 1)], 2)])]);
        }), 128))])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_9, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(handleObjct), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(handleClick)(index)
          }, [index == 2 ? (_openBlock(), _createElementBlock("a", _hoisted_11, _toDisplayString(item.name), 1)) : (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(item.name), 1))], 8, _hoisted_10);
        }), 128))])])]),
        _: 1
      }), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(boxList), (items, i) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tk-type-item",
          key: i
        }, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.content, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index,
            onClick: $event => _unref(handleSelect)(item, index, i),
            class: _normalizeClass({
              active: item.active
            })
          }, _toDisplayString(item.name), 11, _hoisted_15);
        }), 128))])]);
      }), 128))])]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => show.value = $event),
        round: "",
        position: "bottom",
        style: {
          height: '350px'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", {
          class: "sama-black other",
          onClick: _cache[2] || (_cache[2] = $event => show.value = false)
        }, "返回"), _cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "sama-titles"
        }, "选择杀码或杀肖", -1)), _createElementVNode("div", {
          class: "sama-r other",
          onClick: _cache[3] || (_cache[3] = (...args) => _unref(handleClearSm) && _unref(handleClearSm)(...args))
        }, "杀码清空")]), _createElementVNode("div", _hoisted_18, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(samaList, (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            class: _normalizeClass(_unref(shamabg)(item.name)),
            onClick: $event => handleSelectSaMa(item, _unref(shamabg)(item.name), index),
            key: index
          }, _toDisplayString(item.name < 10 ? '0' + item.name : item.name), 11, _hoisted_19);
        }), 128))])])])]),
        _: 1
      }, 8, ["show"])], 512);
    };
  }
};